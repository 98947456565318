import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIBeneficiaireConnected {

  createAbattement(token, accord_pk, data) {
    const url = `${apiBaseUrl}/accord/${accord_pk}/abattements`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  createFraisSupplementaire(token, data) {
    const url = `${apiBaseUrl}/frais-supplementaires/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteAbattement(token, abattement_pk) {
    const url = `${apiBaseUrl}/abattements/${abattement_pk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url, {'headers': headers}
    )
  }

  deleteFraisSupplementaire(token, id) {
    const url = `${apiBaseUrl}/frais-supplementaires/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  getConnectedBeneficiaire(token) {
    const url = apiBaseUrl + `/connected-beneficiaire/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  updateBeneficiaireProfil (token, sex, first_name, last_name,
    activite, metier, other_first_names, address, code_postal, city,
    birthdate, phone_number, other_phone_number, situation,
    conjoint_first_name, conjoint_last_name, is_conjoint_intermittent) {

    const url = apiBaseUrl + `/connected-beneficiaire/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'sex': sex,
      'first_name': first_name,
      'last_name': last_name,
      'activite': activite,
      'metier': metier,
      'other_first_names': other_first_names,
      'address': address,
      'code_postal': code_postal,
      'city': city,
      'birthdate': birthdate,
      'phone_number': phone_number,
      'other_phone_number': other_phone_number,
      'situation': situation,
      'conjoint_first_name': conjoint_first_name,
      'conjoint_last_name': conjoint_last_name,
      'is_conjoint_intermittent': is_conjoint_intermittent
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }


  // ---------- CHILD
  addChild(token, first_name, last_name, birthdate) {
    const url = apiBaseUrl + `/add-child/`
    var data = {
      'first_name': first_name,
      'last_name': last_name,
      'birthdate': birthdate,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyChild(token, child_pk, first_name, last_name, birthdate) {
    const url = apiBaseUrl + `/child/` + child_pk
    var data = {
      'first_name': first_name,
      'last_name': last_name,
      'birthdate': birthdate,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteChild(token, pk) {
    const url = apiBaseUrl + `/child/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  childDuplicate(token, first_name, last_name, birthdate) {
    const url = `${apiBaseUrl}/child/duplicates?first_name=${first_name}&last_name=${last_name}&birthdate=${birthdate}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }


  // ------------ ACCORD DE PRINCIPE
  createAccord(token, year) {
    const url = apiBaseUrl + `/accord/creation/`
    var data = {
      'year': year,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getAccord(token, accord_pk) {
    const url = apiBaseUrl + `/accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyAccord (token, accord_pk,  data) {
    const url = apiBaseUrl + `/accord/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  askAccordValidation(token, accord_pk, data) {
    const url = apiBaseUrl + `/ask-accord-validation/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }


  // -------- ACCORD DOCUMENTS
  getAllAccordDocuments (token, accord_pk) {
    const url = apiBaseUrl + `/get-all-accord-documents/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createAccordDocument (token, accord_pk, document_type, file) {
    const url = apiBaseUrl + `/create-accord-document/` + accord_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    formData.append('type', document_type);
    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getOwnProtectedAccordDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/accord-document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  modifyAccordDocument (token, document_pk, file) {
    const url = apiBaseUrl + `/modify-accord-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  deleteAccordDocument (token, document_pk) {
    const url = apiBaseUrl + `/delete-accord-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }


  // ------------------- REMBOURSEMENT -------------------
  createRemboursement (token, month, year, children_ids, is_creche, is_gad_pajemploi,
    is_gad_cesu, is_gad_structure, is_pair) {
    const url = apiBaseUrl + `/remboursement/creation/`
    var data = {
      'month': month,
      'year': year,
      'children': children_ids,
      'is_creche': is_creche,
      'is_gad_pajemploi': is_gad_pajemploi,
      'is_gad_cesu': is_gad_cesu,
      'is_gad_structure': is_gad_structure,
      'is_pair': is_pair,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getRemboursement(token, remboursement_pk) {
    const url = apiBaseUrl + `/remboursement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyRemboursement (token, remboursement_pk, children_ids, is_creche, is_gad_pajemploi,
    is_gad_cesu, is_gad_structure, is_pair) {
    const url = apiBaseUrl + `/remboursement/` + remboursement_pk
    var data = {
      'children': children_ids,
      'is_creche': is_creche,
      'is_gad_pajemploi': is_gad_pajemploi,
      'is_gad_cesu': is_gad_cesu,
      'is_gad_structure': is_gad_structure,
      'is_pair': is_pair,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  askRemboursementValidation(token, remboursement_pk) {
    const url = apiBaseUrl + `/ask-remboursement-validation/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  modifyWantsFraisDeplacement(token, remboursement_pk, want_frais_deplacement) {
    const url = apiBaseUrl + `/modify-wants-frais-deplacement/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'want_frais_deplacement': want_frais_deplacement,
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  // -------- GARDE INFORMATIONS --------------
  createGardeCaracteristiques(token, data) {
    const url = `${apiBaseUrl}/garde-caracteristiques/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyGardeCaracteristiques(token, id, data) {
    const url = `${apiBaseUrl}/garde-caracteristiques/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  // -------- REMBOURSEMENT DOCUMENTS
  getAllRemboursementDocuments (token, remboursement_pk) {
    const url = apiBaseUrl + `/get-all-remboursement-documents/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createRemboursementDocument (token, remboursement_pk, document_type, file, nb_hours, ccn) {
    const url = apiBaseUrl + `/create-remboursement-document/` + remboursement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file)
    formData.append('type', document_type)
    if (nb_hours) {
      formData.append('nb_hours', nb_hours)
    }
    if (ccn) {
      formData.append('ccn', ccn)
    }
    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getOwnProtectedRemboursementDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/remboursement-document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  modifyRemboursementDocument (token, document_pk, file, nb_hours) {
    const url = apiBaseUrl + `/modify-remboursement-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file)
    if (nb_hours) {
      formData.append('nb_hours', nb_hours)
    }
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  deleteRemboursementDocument (token, document_pk) {
    const url = apiBaseUrl + `/delete-remboursement-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  setNbHoursCCNRemboursementDocument (token, document_pk, nb_hours, ccn) {
    const url = apiBaseUrl + `/set-remboursement-document-nb-hours/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'nb_hours': nb_hours,
      'ccn': ccn
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  // --------- PERIODE EMPLOI --------------
  createPeriodeEmploi(token, remboursement_document_pk, periode_emplois) {
    const url = `${apiBaseUrl}/remboursement-document/${remboursement_document_pk}/periodes-emplois`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.post(
      url, periode_emplois,
      {
        'headers': headers,
      }
    )
  }

  deletePeriodeEmploi(token, periode_emploi_pk) {
    const url = `${apiBaseUrl}/periodes-emplois/${periode_emploi_pk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }
}
