<template>
  <div class="BeneficiaireProfileEdition" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Editer votre profil</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="beneficiaireUpdateAPIErrorModal" id="beneficiaireUpdateAPIErrorModal" hide-footer title="Erreur lors la mise à jour de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la modification de vos informations,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('beneficiaireUpdateAPIErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="beneficiaireUpdatedModal" id="beneficiaireUpdatedModal" hide-footer title="Votre profil a bien été mis à jour">
              <div class="text-center">
                <p><strong>Votre profil a bien été mis à jour</strong></p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('beneficiaireUpdatedModal')">Fermer</b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isProfileLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form>
              <div class="row">
                <div class="col-md-4">
                  <b-form-group id="modify-beneficiaire-sex-group"
                    label="Vous êtes"
                    label-for="modify-beneficiaire-sex-input"
                    class="mandatory-input">
                    <b-form-select
                      v-model="sex"
                      id="modify-beneficiaire-sex-input"
                      @input="$v.sex.$touch()"
                      :state="$v.sex.$dirty ? !$v.sex.$error : null"
                      :options='genderOptions'>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group id="modify-beneficiaire-first_name-group" label="Prénom" label-for="modify-beneficiaire-first_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="firstName" type="text"
                      maxlength="29"
                      placeholder="Prénom"
                      id="modify-beneficiaire-first_name-input"
                      @input="$v.firstName.$touch()"
                      :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-form-group id="modify-beneficiaire-last_name-group" label="Nom" label-for="modify-beneficiaire-last_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="lastName" type="text"
                      maxlength="149"
                      placeholder="Nom"
                      id="modify-beneficiaire-last_name-input"
                      @input="$v.lastName.$touch()"
                      :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="modify-beneficiaire-other_first_names-group">
                    <template slot="label">Autre(s) prénom(s) <em>si pertinent</em></template>
                    <b-form-input
                      v-model="otherFirstNames" type="text"
                      maxlength="199"
                      placeholder="Nom marital"
                      id="modify-beneficiaire-other_first_names-input"
                      @input="$v.otherFirstNames.$touch()"
                      :state="$v.otherFirstNames.$dirty ? !$v.otherFirstNames.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.otherFirstNames.$error">Votre nom doit comporter entre 2 et 200 caractères</div>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <div v-if="$browserDetect.isSafari">
                    <b-form-group id="modify-beneficiaire-birthdate-group" label="Date de naissance" label-for="modify-beneficiaire-birthdate-input" class="mandatory-input">
                      <b-form-datepicker
                        id="modify-beneficiaire-birthdate-input"
                        name="modify-beneficiaire-birthdate-input"
                        locale="fr"
                        :max="maxBirthdate"
                        :show-decade-nav="true"
                        placeholder="Choisissez une date"
                        v-model="birthdate" class="mb-2"
                        @input="$v.birthdate.$touch()"
                        :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                      </b-form-datepicker>
                      <small class="text-danger" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <b-form-group id="modify-beneficiaire-birthdate-group" label="Date de naissance" label-for="modify-beneficiaire-birthdate-input" class="mandatory-input">
                      <b-form-input
                        v-model.trim.lazy="birthdate" type="date"
                        id="modify-beneficiaire-birthdate-group"
                        placeholder="AAAA-MM-DD"
                        @input="$v.birthdate.$touch()"
                        :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                      </b-form-input>
                      <div v-if="$browserDetect.isIE" class="text-info">
                        <small>Vous utilisez Internet Explorer, veuillez entrer votre
                        date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                      </div>
                      <div class="form-error" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <b-form-group id="modify-beneficiaire-situation-group"
                label="Votre situation maritale"
                label-for="modify-beneficiaire-situation-input"
                class="mandatory-input">
                <b-form-select
                  v-model="situation"
                  id="modify-beneficiaire-situation-input"
                  @input="$v.situation.$touch()"
                  :state="$v.situation.$dirty ? !$v.situation.$error : null"
                  :options='situationTypeArrayForm'>
                </b-form-select>
              </b-form-group>

              <div v-if="situation === 'MAR' || situation === 'PACS' || situation === 'VIEMAR'">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-conjointFirstName-group" label="Prénom de votre conjoint" label-for="new-beneficiaire-conjointFirstName-input" class="mandatory-input">
                      <b-form-input
                        v-model="conjointFirstName" type="text"
                        maxlength="150"
                        placeholder="Prénom"
                        id="new-beneficiaire-conjointFirstName-input"
                        @input="$v.conjointFirstName.$touch()"
                        :state="$v.conjointFirstName.$dirty ? !$v.conjointFirstName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.conjointFirstName.$error">Le prénom doit comporter entre 2 et 150 caractères</div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="new-beneficiaire-conjointLastName-group" label="Nom de votre conjoint" label-for="new-beneficiaire-conjointLastName-input" class="mandatory-input">
                      <b-form-input
                        v-model="conjointLastName" type="text"
                        maxlength="149"
                        placeholder="Nom"
                        id="new-beneficiaire-conjointLastName-input"
                        @input="$v.conjointLastName.$touch()"
                        :state="$v.conjointLastName.$dirty ? !$v.conjointLastName.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="$v.conjointLastName.$error">Le nom doit comporter entre 2 et 150 caractères</div>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group>
                  <label>Votre conjoint est-il également <strong>intermittent</strong> ?</label>
                  <b-form-radio-group
                    id="isConjointIntermittent-input"
                    v-model="isConjointIntermittent"
                    :options="trueFalseOptions"
                    name="isConjointIntermittent-input"
                  ></b-form-radio-group>
                </b-form-group>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="modify-beneficiaire-phone-number-group" label="Votre numéro de téléphone portable"
                    label-for="modify-beneficiaire-phone-number-input" class="mandatory-input">
                    <b-form-input
                      type="tel" maxlength="10"
                      v-model.trim.lazy="phoneNumber"
                      id="modify-beneficiaire-phone-number-input"
                      @input="$v.phoneNumber.$touch()"
                      :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null"
                      placeholder="Entrez votre numéro de téléphone portable">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="modify-beneficiaire-other-phone-number-group"
                      label-for="modify-beneficiaire-other-phone-number-input">
                      <template slot="label">Votre autre numéro de téléphone fixe ou portable <em>(facultatif)</em></template>
                      <b-form-input
                        type="tel" maxlength="10"
                        v-model.trim.lazy="otherPhoneNumber"
                        id="modify-beneficiaire-other-phone-number-input"
                        @input="$v.otherPhoneNumber.$touch()"
                        :state="$v.otherPhoneNumber.$dirty ? !$v.otherPhoneNumber.$error : null"
                        placeholder="Autre numéro de téléphone">
                      </b-form-input>
                    </b-form-group>
                </div>
              </div>


              <b-form-group id="modify-beneficiaire-address-group" label="Votre adresse" label-for="modify-beneficiaire-address-input" class="mandatory-input">
                <b-form-input
                  v-model="address" type="text"
                  maxlength="149"
                  placeholder="Renseignez votre adresse"
                  id="modify-beneficiaire-address-input"
                  @input="$v.address.$touch()"
                  :state="$v.address.$dirty ? !$v.address.$error : null">
                </b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="modify-beneficiaire-postalCode-group" label="Votre code postal" label-for="modify-beneficiaire-postalCode-input" class="mandatory-input">
                    <b-form-input
                      v-model.trim="postalCode" type="text"
                      maxlength="5"
                      placeholder="Code postal"
                      id="postalCode-input"
                      @input="$v.postalCode.$touch()"
                      :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="!$v.postalCode.minLength || !$v.postalCode.maxLength ">Votre cote postal doit comporter 5 chiffres</div>
                    <div class="form-error" v-if="!$v.postalCode.numeric">Votre code postal ne peut contenir que des chiffres</div>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="modify-beneficiaire-city-group" label="Votre ville" label-for="modify-beneficiaire-city-input" class="mandatory-input">
                    <b-form-input
                      v-model="city" type="text"
                      maxlength="149"
                      placeholder="Ville"
                      id="modify-beneficiaire-city-input"
                      @input="$v.city.$touch()"
                      :state="$v.city.$dirty ? !$v.city.$error : null">
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>

              <hr>

              <b-form-group id="modify-beneficiaire-activite-group"
                label="Vous êtes"
                label-for="modify-beneficiaire-activite-input"
                class="mandatory-input">
                <b-form-select
                  v-model="activite"
                  id="modify-beneficiaire-activite-input"
                  @input="$v.activite.$touch()"
                  :state="$v.activite.$dirty ? !$v.activite.$error : null"
                  :options='activiteTypeArrayForm'>
                </b-form-select>
              </b-form-group>

              <b-form-group id="modify-beneficiaire-metier-group"
                label="Votre métier exercé"
                label-for="modify-beneficiaire-metier-input"
                class="mandatory-input">
                <b-form-input
                  v-model.trim.lazy="metier" type="text"
                  placeholder="Métier"
                  id="modify-beneficiaire-metier-group"
                  @input="$v.metier.$touch()"
                  :state="$v.metier.$dirty ? !$v.metier.$error : null">
                </b-form-input>
              </b-form-group>

              <b-button
                pill block :variant="updateBeneficiaireButtonStyle" class="mt-2"
                @click='updateBeneficiaire'
                :disabled="isInvalidBeneficiaire">
                  <b-spinner small label="Create Salarie Spinner" v-if="updateBeneficiaireInProcess"></b-spinner>
                   {{ updateBeneficiaireButtonText }}
              </b-button>
            </b-form>

            <hr>

            <div class="row text-center">
              <div class="col-12">
                <contactComponent />
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric} from 'vuelidate/lib/validators'
import { isDate } from '@/validators/validators'
import { APIBeneficiaireConnected } from '@/api/APIBeneficiaireConnected'
import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBeneficiaireConnectedService = new APIBeneficiaireConnected()

export default {
  name: 'BeneficiaireProfileEdition',
  components: {
    contactComponent,
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {

      isProfileLoading: false,

      sex: 'H',
      firstName: '',
      lastName: '',
      otherFirstNames: '',
      maxBirthdate: today,
      birthdate: '',
      phoneNumber: '',
      otherPhoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      situation: 'CELIB',
      conjointFirstName: '',
      conjointLastName: '',
      isConjointIntermittent: false,

      activite: 'ART',
      metier: '',

      activiteTypeArrayForm: [
        { text: 'Artiste', value: 'ART' },
        { text: 'Technicien', value: 'TEC' },
      ],

      trueFalseOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value:  false },
      ],

      genderOptions: [
        { text: 'Un homme', value: 'H' },
        { text: 'Une femme', value:  'F' },
        { text: 'Ne se prononce pas', value:  'N' },
      ],

      situationTypeArrayForm: [
        { text: 'Célibataire', value: 'CELIB' },
        { text: 'Marié(e)', value: 'MAR' },
        { text: 'Divorcé(e)', value: 'DIV' },
        { text: 'Séparé(e)', value: 'SEP' },
        { text: 'Pacsé(e)', value: 'PACS' },
        { text: 'Vie maritale / Concubinage', value: 'VIEMAR' },
      ],
      updateBeneficiaireButtonText: 'Mettre à jour votre profil',
      updateBeneficiaireButtonStyle: 'outline-info',
      updateBeneficiaireInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidBeneficiaire () {

      if (this.$v.firstName.$invalid || this.$v.lastName.$invalid ||
          this.$v.otherFirstNames.$invalid || this.$v.birthdate.$invalid || this.$v.phoneNumber.$invalid ||
          this.$v.otherPhoneNumber.$invalid || this.$v.address.$invalid || this.$v.postalCode.$invalid ||
          this.$v.city.$invalid || this.$v.situation.$invalid || this.$v.conjointFirstName.$invalid ||
          this.$v.conjointLastName.$invalid || this.$v.isConjointIntermittent.$invalid ||
          this.$v.activite.$invalid || this.$v.metier.$invalid || this.updateBeneficiaireInProcess) {
        return true
      }
      return false
    }
  },
  validations: {
    sex: {
      required
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    otherFirstNames: {
      minLength: minLength(2),
      maxLength: maxLength(200),
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    otherPhoneNumber: {
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    postalCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      numeric
    },
    city: {
      required
    },
    situation: {
      required
    },
    conjointFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    conjointLastName: {
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    isConjointIntermittent: {
      required
    },
    activite: {
      required,
    },
    metier: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(70),
    },
  },

  created: function() {
    this.getConnectedBeneficiaire()
  },

  methods: {
    getConnectedBeneficiaire () {
      this.isProfileLoading = true
      apiBeneficiaireConnectedService.getConnectedBeneficiaire(this.token)
        .then((result) => {

          this.sex = result.data.sex
          this.firstName = result.data.user.first_name
          this.lastName = result.data.user.last_name
          this.otherFirstNames = result.data.other_first_names
          this.birthdate = result.data.birthdate
          this.phoneNumber = result.data.phone_number
          this.otherPhoneNumber = result.data.other_phone_number
          this.address = result.data.address
          this.postalCode = result.data.code_postal
          this.city = result.data.city
          this.activite = result.data.activite
          this.situation = result.data.situation
          this.conjointFirstName = result.data.conjoint_first_name
          this.conjointLastName = result.data.conjoint_last_name
          this.isConjointIntermittent = result.data.is_conjoint_intermittent
          this.metier = result.data.metier

        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('BeneficiaireProfileEdition getConnectedBeneficiaire API Error : ' + String(error))
        })
        .finally(() => {
          this.isProfileLoading = false
        })
    },

    updateBeneficiaire () {
      this.updateBeneficiaireInProcess = true
      this.updateBeneficiaireButtonText = "Mise à jour en cours"
      this.updateBeneficiaireButtonStyle = 'secondary'
      apiBeneficiaireConnectedService.updateBeneficiaireProfil(
        this.token, this.sex, this.firstName, this.lastName, this.activite, this.metier, this.otherFirstNames,
        this.address, this.postalCode, this.city, this.birthdate, this.phoneNumber, this.otherPhoneNumber,
        this.situation, this.conjointFirstName, this.conjointLastName, this.isConjointIntermittent)
      .then(() => {
        this.$refs.beneficiaireUpdatedModal.show()
      })
      .catch((error) => {
        this.$refs.beneficiaireUpdateAPIErrorModal.show()
        throw new Error('BeneficiaireProfileEdition updateBeneficiaire API Error : ' + String(error))
      })
      .finally(() => {
        this.updateBeneficiaireInProcess = false
        this.updateBeneficiaireButtonText = "Mettre à jour votre profil"
        this.updateBeneficiaireButtonStyle = 'outline-info'
      })

    },
  }
}
</script>
